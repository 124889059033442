   .order-summary {
	padding: 0 10px;
	color: #495057;
  }

  .order-summary:nth-child(even) {
	background-color: #efefef;
  }
  
  .order-summary .order-header {
	font-weight: bold;
	text-decoration: underline;
	color: #495057;
	font-size: 1.5rem;
	padding: 0;
	background: none;
  }

  .order-summary .pickup-time {
	float: right;
	margin-top: -33px;
	margin-right: 10px;
	font-size: .75rem;
    transform: translateZ(0); /* Trigger GPU acceleration */
  }

  .order-summary .order-body {
	padding: 0;
	padding-left: 10px;
	background: none;
	margin-top: 0;
  }
  
  .order-summary .order-item {
	font-size: 1.3em;
	line-height: 1.3em;
    width: 100%;
  }
  
  .order-summary .delivery-tag {
	padding-left: 10px;
	font-size: 1.3em;
	line-height: 1.3em;
	text-align: left;
	text-decoration: underline;
  }
  
  .order-summary .order-note {
	margin: 5px;
	padding: 15px;
	padding-top: 10px;
	font-size: .9em;
	line-height: 1.1em;
	border-top: 1px solid #fff;
  }
  
  .order-summary .modifiers {
	padding: 2px;
	font-size: .75em;
	clear: both;
  }
  
  .order-summary .notes {
	font-style: italic;
	font-size: .75em;
	padding: 2px;
  }
  
  .order-summary .name {
	font-weight: bold;
  }
  
  .order-summary .lineitem {
	text-align: center;
	vertical-align: middle;
	height: 100%;
	font-size: 22px;
	padding: 10px;
  }

  .dropdown-menu:has(.order-summary) {
	width: 24rem;
  }

  .react-datepicker .react-datepicker__navigation--next--with-time {
	right: 130px;
  }
  
  .react-datepicker__aria-live {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
