#clock {
	display: flex;
	flex-direction: column;
	justify-content: right;
	align-items: right;
	margin-left: .5rem;
}

#clock .time {
	font-size: 2.2rem;
	width: 100%;
	text-align: right;
	line-height: 1em;
}

#clock .date-time {
	font-size: .9rem;
	width: 100%;
	text-align: right;
}