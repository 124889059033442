#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;  /* Ensures that the footer can be absolutely positioned relative to this container */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

.app-main {
  flex: 1;
  overflow-y: auto;  /* Scroll inside main content area if needed */
}

.app-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;  /* Ensures footer extends full width of the viewport */
  height: 40px; /* Adjust height as needed */
  padding: 0;
  background-color: #f8f9fa; /* Example background color, change as needed */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Optional: adds shadow for better visibility */
  z-index: 10; /* Ensures footer stays on top of other content */
}

.app-footer .app-footer__inner { 
  height: 40px;
}

.input-group-container {
  display: flex;
  align-items: center;  /* Ensures vertical alignment is centered */
  justify-content: space-between;  /* Pushes the child elements to either end */
  gap: 10px;  /* Optional: Provides space between children if they wrap */
}

.input-group-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.input-group-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
  
  .input-250px {
	width: 250px;
  }
  
  .header-container {
	/*display: flex;*/
	justify-content: space-between;
	align-items: center;
	height: 60px;
	margin-bottom: 10px;
  }
  
  .card-body h4 {
	margin: 20px 0;
  }

  .a-bit-smaller {
	font-size: 1.65rem;
  }

  /* Header Dropdowns */
  .dropdown-menu {
    width: 19rem;
  }

  .dropdown-menu::before, .dropdown-menu::after {
    content: none !important;
  }

  .dropdown-menu .nav-item.nav-item-header {
    text-transform: none;
    font-size: 0.88rem;
    padding-top: 0;
    font-weight: normal;
  }

  .nav-item h3 {
    padding-left: 10px;
  }

  .nav-item a {
    font-size: 1.25rem;
  }

  /* Sidebar Menu Classes */
  .metis-hidden ul.metismenu-container li.metismenu-item a.metismenu-link {
    padding-left: 12px;
  }

  /* Sidebar Menu Classes */
  .metis-hidden ul.metismenu-container li.metismenu-item a.metismenu-link i.metismenu-icon {
    display: none;
  }

/* Red/Green background animations for table cell updates */
  .dddatatable tr td, .meta-card {
	transition: background-color .5s ease-in-out;
  }

  .dddatatable > tbody > tr:not(.expanded-row) > td:has(.cell-transition-success), .meta-card.cell-transition-success {
	  background-color: green;
  }
	
  .dddatatable > tbody > tr:not(.expanded-row) > td:has(.cell-transition-failure), .meta-card.cell-transition-failure {
	  background-color: red;
  }

/* Card styles for meta cards on recipes */
.meta-cards {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
}

.meta-card {
	width: 200px; /* Adjust the width as needed */
	margin: 10px;
	display: inline-block;
	vertical-align: top; /* Ensure cards align properly */
}

/* Editable Row Indicator */
.editable {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.editable:hover:not(:has(input))::after {
  content: '\f303'; /* Unicode for the Font Awesome pencil icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900; /* Required for solid icons */
  margin-left: 8px; /* Adjust the spacing as needed */
  color: #000; /* Adjust the color as needed */
  opacity: .5;
}

.editable-always:not(:has(input))::after {
  content: '\f303'; /* Unicode for the Font Awesome pencil icon */
  font-family: 'Font Awesome 6 Free';
  font-weight: 900; /* Required for solid icons */
  margin-left: 8px; /* Adjust the spacing as needed */
  color: #000; /* Adjust the color as needed */
  opacity: .5;
}

/* Draggable Table Row CSS */

/* General table styling */
.draggable-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
}

/* Header styling */
.draggable-table th {
    background-color: #f4f4f4;
    color: #333;
    padding: 12px 15px;
    text-align: left;
    font-size: .875rem;
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
}

/* Body row styling */
.draggable-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #eaeaea;
    font-size: .875rem;
}

/* Alternating row colors */
.draggable-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.draggable-table tbody tr:nth-child(odd) {
    background-color: #fff;
}

/* Row hover effect */
.draggable-table tbody tr:hover {
    background-color: #eaeaea;
}

/* Draggable row cursor */
.draggable-table tr[draggable="true"] {
    cursor: grab;
}

.draggable-table tr[draggable="true"]:active {
    cursor: grabbing;
}

/* Conditional row styling */
.bold-row {
    font-weight: bold;
    border-top: 2px solid #000;
}

.italic-row {
    font-style: italic;
}

.mouse-arrow {
	cursor: default;
}

.mouse-pointer {
  cursor: pointer;
}

.true-size-div {
  display: inline-block;
  margin: 5px;
}

/* Action Icons */
.action-icon {
  padding: 3px;
  margin: 2px;
  cursor: default;
}

/* Fade out */
.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Ingredients Page */
.new-item-modal.modal-dialog {
  max-width: 50vw;
}

.component-tabs {
  padding:0;
}
.component-tabs .rc-tabs-nav .rc-tabs-tab::before {
  content: "";
  border-radius: 15px;
  background: #545cd8;
  transition: all 0.2s;
  height: 4px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  opacity: 0;
}

/* Inventory Single Count */
.checkmark-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.checkmark-list li {
  position: relative;
  padding-left: 20px; /* Adjust padding to make space for the checkmark */
}

.checkmark-list li::before {
  content: '○'; /* Circle symbol */
  position: absolute;
  left: 0;
}

.btn.action {
  display: inline-block;
  padding: 5px 10px;
  font-size: 1em;
}


.btn-icon-vertical i.fa-solid {
  opacity: 1;
  color: #0d6efd;
}

/* Header Order Count Section */
.header-count {
  padding: 0 15px;
  text-align: center;
  position: relative;
}

.header-count:not(.screen-count)::before {
  position: absolute;
  left: -1px;
  top: 50%;
  background: #dee2e6;
  width: 1px;
  height: 30px;
  margin-top: -15px;
  content: "";
}

.audio-button {
  width: 80px;
  font-size: 2rem;
  cursor: pointer;
}

.no-caret::after {
  content: none !important;
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


/* General Header DropdownMenu styles */
.dropdown-menu-custom {
  width: 350px;
  padding: 0;
  margin: 0;
}

/* Row styles */
.row-custom {
  text-align: center;
  padding: 0;
  margin: 0;
  height: 90px;
  display: flex;
}

.row-custom:not(:last-child) {
  border-bottom: 2px solid #f8f9fa;
}

/* Column styles */
.col-custom {
  min-width: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.col-custom * {
  margin-top: -5px;
}

.col-custom:not(:last-child) {
  border-right: 2px solid #f8f9fa;
}

/* Icon styles */
.menu-icon {
  font-size: 1.5rem;
  color: #0d6efd;
  padding-bottom: .5rem;
}

.noscroll {
  overflow: hidden; /* Prevent scrolling of parent */
}

.tablet-scroll-area {
  height: calc(100vh - 180px);
  width: 98vw;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.checklists {
  height: calc(100vh - 180px);
  width: 98vw;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.recipebox {
  height: calc(100vh - 310px);
  width: 98vw;
  overflow-y: scroll;
}

.scrollable-y {
  overflow-y: scroll;
}

.fill-area {
  height: 100%;
  width: 100%;
}

.future-order-list {
  max-height: calc(100vh - 130px);
}

.future-order-list .order-body {
  overflow: hidden;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px; /* This gap will act as the internal border */
  background-color: #f8f9fa; /* Border color */
}

.grid-item {
  background: white; /* Or any desired background */
  padding: 8px;
  padding-bottom: 20px;
  text-align: center;
  cursor: pointer;
}
